import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import BlogDetailContainer from '../containers/BlogDetailContainer'

export default function BlogDetails(props) {
  console.log("hello")
  return (
    <Layout
      pageInfo={{ pageName: "Blog Detail" }}
      styleClass="home-page"
      mainClass="main-about"
      location={props.location.pathname}>
      <SEO title="Blog Detail" />
      <BlogDetailContainer blog={props.data && props.data.allContentfulBlogs.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    allContentfulBlogs(filter: {contentful_id: {eq: $slug}}) {
      nodes {
        contentful_id
        createdAt
        tag
        title
        url
        description {
          content {
            content {
              value
            }
          }
          childContentfulRichText {
            html
          }
        }
        media {
          file {
            url
          }
        }
      }
      totalCount
    }
  }
`